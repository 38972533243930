import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { initGA, trackPageView } from "./analytics";
import translatorPlugin from "@/plugins/translatorPlugin";
import routerPlugin from "@/plugins/routerPlugin";
initGA();
// Track page navigation
router.afterEach((to) => {
    // Use the router's 'to' parameter to get the full URL
    trackPageView(to.fullPath);
});
createApp(App)
    .use(store)
    .use(router)
    .use(routerPlugin)
    .use(translatorPlugin)
    .mount("#app");
