export default {
    header: {
        welcome_to: "Welkom bij",
        sub_title: "Van brainstorm tot directiekamer: kantoorruimtes ontworpen voor elke behoefte van de moderne innovator.",
        learn_more: "Leer meer",
        rent_office: "Huur een kantoor"
    },
    about_us: {
        top_text: "Snel & eenvoudig",
        sub_text: "Onze werkwijze",
        find_solution: {
            title: "Vind uw oplossing",
            description: "We hebben verschillende kantoorruimtes beschikbaar om uit te kiezen."
        },
        agreement: {
            title: "Overeenkomst",
            description: "Na het kiezen van uw werkruimte vinden we een overeenkomst."
        },
        details: {
            title: "Bespreken van de details",
            description: "Samen bespreken we uw doelen en verwachtingen."
        },
        enjoy: {
            title: "Geniet van uw kantoor",
            description: "Uw kantoor staat voor u klaar!"
        },
        challenges_title: "De uitdagingen waar innovators voor staan",
        challenges_description: "Innovators zijn vaak zeer bekwaam in hun respectievelijke domeinen, maar kunnen toch uitdagingen ondervinden op andere cruciale gebieden van het runnen van een bedrijf, zoals financiën, marketing en juridische zaken. Bij Innovators Hub heeft onze uitgebreide ervaring, opgedaan uit decennia van bedrijfsvoering en succesvolle bedrijfsstartups, ons geïnspireerd om een ervaringsgerichte aanpak te bieden die uw organisatie en bedrijf aanzienlijk kan verbeteren.",
        solution_title: "Onze oplossing: innovatie en zakendoen overbruggen",
        solution_description: "Onze uitgebreide aanpak fungeert als een brug tussen innovatieve ideeën en praktische bedrijfsstrategieën. Met onze diepgaande expertise op het gebied van financiën, marketing, juridische zaken en andere essentiële gebieden, bieden we de nodige katalysator om uw organisatie naar nieuwe hoogten te stuwen."
    },
    partners: {
        top_text: "Ontmoet onze gewaardeerde partners",
        sub_text: "Onze partners - Innovator Insiders",
        go_watts: {
            name: "Go Watts",
            description: "Ontzorgd elektrisch laden",
            visit_website: "Bezoek hun website"
        },
        groep_coenen: {
            name: "Groep Coenen",
            description: "Houd het simpel - Houd het efficiënt",
            visit_website: "Bezoek hun website"
        }
    },
    contact_us: {
        top_text: "Neem contact met ons op",
        sub_text: "Innovatie begint met een gesprek. Begin er vandaag nog een met ons.",
        form: {
            name_label: "Naam:",
            message_label: "Bericht:",
            send_button: "Versturen"
        },
        connect_with_us: "Verbind met ons",
        socials: {
            facebook: "FACEBOOK",
            linkedin: "LINKEDIN"
        }
    },
    footer: {
        vat: "BTW",
        number_short: "Nr.",
        view_on_gm: "Bekijk op google maps"
    }
}

