export default {
    our_assets: "Onze troeven",
    for_your_comfort: "Alles Voor Uw Comfort",
    general_facilities: "Algemene faciliteiten",
    general_facilities_desc: "Bij Innovators Hub gaat het niet alleen om de ruimte, maar ook om de extra’s die uw dag aangenamer maken. Profiteer van toiletfaciliteiten op elke verdieping, en zelfs twee douches op de begane grond. Of u nu een drukke dag hebt of even wilt ontspannen, wij hebben aan alles gedacht.",
    our_offices: "Onze Kantoren",
    location: {
        title: "Locatie",
        description: "De ideale kantoorlocatie: Kortenberg is een uitstekende locatie voor uw kantoorruimte, strategisch gelegen tussen Brussel en Leuven. Deze gunstige ligging biedt niet alleen een vlotte bereikbaarheid via belangrijke verkeersaders en het openbaar vervoer, maar zet uw bedrijf ook in een omgeving die zowel dynamisch als rustgevend is.",
        free_parking_title: "Gratis Parkeren",
        free_parking_text: "Ruime parkeergelegenheid voor u en uw klanten.",
        charging_stations_title: "Laadstations",
        charging_stations_text: "Snelle en efficiënte oplaadpunten voor uw elektrische voertuigen.",
        public_transport_title: "Openbaar Vervoer",
        public_transport_text: "Treinstation op 1.5km, bushalte op 400m."
    },
    facilities: {
        facilities: "Faciliteiten",
        internet: "High-speed Internet: Betrouwbaar en snel internet in alle kantoorruimtes",
        meeting: "Vergaderzalen: uitgeruste vergaderzalen beschikbaar voor huurders.",
        kitchen: "Koffie en Keukenfaciliteiten: koffie en een volledig uitgeruste keuken.",

        flexibility: "Flexibiliteit",
        short_term: "Korte Huurtermijnen: Optie om per dag, week of maand te huren.",
        scalability: "Schaalbaarheid: Makkelijk uit te breiden als uw bedrijf groeit.",

        services: "Extra Diensten",
        advice: "Boekhoudkundig en Juridisch Advies: Externe deskundigen beschikbaar voor consult.",
        printing: "Print- en Kopieerservices: Printers en kopieerapparaten beschikbaar voor algemeen gebruik.",

        ambience_design: "Sfeer en Design",
        durability: "Duurzaamheid: Milieuvriendelijke voorzieningen zoals zonnepanelen, recycling, etc."
    },
    office_types: {
        compact_but_complete: "Compact maar compleet",
        office_for_2: "Kantoor voor 2 tot 3 personen",
        area_20_25: "20 tot 21m²",
        general_text_small: "Onze tweepersoonskantoren zijn perfect voor freelancers, kleine startups of als satellietkantoor. Deze kantoren zijn volledig uitgerust met bureaus en bureaustoelen, maar kunnen ook leeg worden opgeleverd als u dat wenst.",
        small_office_alt: "Klein kantoor met 2 personen aan het werk",

        room_to_grow: "Ruimte om te groeien",
        office_up_to_4: "Kantoor tot 4 tot 6 personen",
        area_25_30: "23 tot 27m²",
        general_text_medium: "Met ruimte voor een team van vier zijn deze kantoren ideaal voor kleinere bedrijven of afdelingen die samen willen werken in een professionele omgeving. Net als onze andere kantoren, zijn deze ruimtes volledig uitgerust maar kunnen ze op verzoek leeg worden geleverd.",
        medium_office_alt: "Middelgroot kantoor met maximaal 4 personen aan het werk",

        max_space_max_productivity: "Maximale Ruimte, Maximale Productiviteit",
        office_up_to_10: "Kantoor voor 10 tot 16 personen",
        area_48: "51 tot 120m²",
        general_text_large: "Onze grootste kantoren bieden plek aan teams tot 10 personen en zijn uitgerust met alles wat u nodig heeft om productief te zijn. Net als bij onze kleinere opties kunnen meubels worden verwijderd om aan uw behoeften te voldoen.",
        large_office_alt: "Groot kantoor met maximaal 10 personen aan het werk"
    }

};
