<template>
  <section id="contact-us">
    <div id="contact-us-title-container">
      <p class="section-top-text" id="contact-top-text">{{ translator.trans("general.contact_us.top_text") }}</p>
      <h2 class="section-sub-text" id="contact-sub-text">{{ translator.trans("general.contact_us.sub_text") }}</h2>
    </div>
    <div class="contact-content">
      <div class="contact-form-container">
        <form id="contactForm">
          <label for="name">{{ translator.trans("general.contact_us.form.name_label") }}</label>
          <input class="form-input" type="text" id="name" v-model="name">
          <label for="message">{{ translator.trans("general.contact_us.form.message_label") }}</label>
          <textarea class="form-input" id="message" v-model="message"></textarea>
          <button id="submit-btn" type="button" @click="sendMail">
            <div id="submit-btn-content-container">
              <span>{{ translator.trans("general.contact_us.form.send_button") }}</span>
              <div class="envelope-container">
                <div class="envelope-top"></div>
                <div class="envelope-left"></div>
                <div class="envelope-back">
                  <div class="envelope-paper">
                  </div>
                </div>
                <div class="envelope-right"></div>
                <div class="envelope-bottom"></div>
              </div>
            </div>
          </button>
        </form>
      </div>

      <div class="contact-socials-container">
        <h2 class="section-top-text">{{ translator.trans("general.contact_us.connect_with_us") }}</h2>
        <div id="contact-social-icons">
          <div class="social-card">
            <p class="social-card-title">{{ translator.trans("general.contact_us.socials.facebook") }}</p>
            <a class="social-card-link" href="https://www.facebook.com/innovatorshubkortenberg" target="_blank">
              <div class="social-link-container">
                <p>innovatorshub</p>
                <div class="icon-flip-container">
                  <div class="icon-flipper">
                    <div class="icon-front"></div>
                    <div class="icon-back">
                      <img class="icon-social-img" src="@/assets/images/socials/facebook.svg" alt="Facebook">
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div class="social-card">
            <p class="social-card-title">{{ translator.trans("general.contact_us.socials.linkedin") }}</p>
            <a class="social-card-link" href="https://www.linkedin.com/company/innovatorshub-kortenberg/?viewAsMember=true" target="_blank">
              <div class="social-link-container">
                <p>InnovatorsHub Kortenberg</p>
                <div class="icon-flip-container">
                  <div class="icon-flipper">
                    <div class="icon-front"></div>
                    <div class="icon-back">
                      <img class="icon-social-img" src="@/assets/images/socials/linkedin.svg" alt="LinkedIn">
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>

    </div>
  </section>
</template>

<script lang="ts" setup>
import {ref} from "vue";
import {getCurrentInstance} from "vue";

const name = ref("");
const message = ref("");
const instance = getCurrentInstance();
const translator = instance?.appContext.config.globalProperties.$translatorUtils;
const sendMail = () => {
  window.location.href =
      `mailto:info@innovatorshub.be?subject=Info Innovatorshub from ${name.value}&body=${encodeURIComponent(message.value)}`;
};


</script>

<style lang="scss" scoped>
@import "@/assets/scss/contact-us";
</style>