<template>
  <nav id="sticky-nav">
    <a href="/">
      <img id="header-nav-icon" src="@/assets/images/logo/innovators-hub-no-background.png" style="width: 100px" alt="Innovators Hub logo">
    </a>
    <!-- Overlay for Mobile -->
    <div id="mobile-overlay" class="mobile-overlay" v-show="isOverlayVisible">
      <div id="mobile-overlay-link-container">
        <button id="close-overlay-btn" @click="toggleOverlay">X</button>
        <a class="nav-link" href="/offices" @click="toggleOverlay"><span>Offices</span></a>
        <a class="nav-link" href="/#about-us" @click="toggleOverlay"><span>About us</span></a>
        <a class="nav-link" href="/#partners" @click="toggleOverlay"><span>Partners</span></a>
        <a class="nav-link" href="/#contact-us" @click="toggleOverlay"><span>Contact us</span></a>
        <div id="nav-mobile-lang-container" >
          <a v-for="lang in availableLanguages"
             :key="lang"
             @click="changeLanguage(lang)"
             class="nav-mobile-lang-dropdown-item"
          >
            <span>{{lang.toUpperCase()}}</span>
            <img class="nav-mobile-flag-icon"
                 :src="require(`@/assets/images/language-flags/${lang}.svg`)"
                 height="12"
                 :alt="language">
          </a>
        </div>
      </div>
    </div>
    <button class="hamburger" id="hamburger-btn" @click="toggleOverlay">&#9776;</button>

    <div id="desktop-navbar">
      <a class="nav-link action" id="offices-nav" href="/offices">
        <span class="action">Offices</span>
      </a>
      <a class="nav-link" id="about-us-nav" href="/#about-us">
        <span>About us</span>
      </a>
      <a class="nav-link" id="partners-nav" href="/#partners">
        <span>Partners</span>
      </a>
      <a class="nav-link" id="contact-us-nav" href="/#contact-us">
        <span>Contact us</span>
      </a>
      <div style="display: inline-block">
        <button id="nav-lang-btn" @click="toggleLanguageVisibility">
          <span>{{language.toUpperCase()}}</span>
          <img class="nav-flag-icon" :src="require(`@/assets/images/language-flags/${language}.svg`)" height="12" :alt="language">
          <span>&triangledown;</span>
        </button>
      </div>
      <div v-show="isLanguageDropdownVisible" class="language-dropdown-content">
        <div>
          <a v-for="lang in availableLanguages"
             :key="lang"
             @click="changeLanguage(lang)"
             class="nav-lang-dropdown-item"
          >
            <span>{{lang.toUpperCase()}}</span>
            <img class="nav-flag-icon"
                 :src="require(`@/assets/images/language-flags/${lang}.svg`)"
                 height="12"
                 :alt="language">
          </a>
        </div>

      </div>

    </div>
  </nav>
  <router-view/>
  <FooterView></FooterView>
</template>

<script lang="ts" setup>
import {getCurrentInstance, ref} from "vue";
import FooterView from "@/components/FooterView.vue";
import Language from "@/types/enums/language";

const instance = getCurrentInstance();
const router = instance?.appContext.config.globalProperties.$routerUtils;
const translator = instance?.appContext.config.globalProperties.$translatorUtils;

const language = ref(localStorage.getItem("language") ?? "en");

function changeLanguage(language: string){
  translator.setLanguage(language);
  router.refreshCurrentPage();
}

translator.setLanguage(language.value);

const isLanguageDropdownVisible = ref(false);
const availableLanguages = Object.values(Language);

const toggleLanguageVisibility = () => {
  isLanguageDropdownVisible.value = !isLanguageDropdownVisible.value;
};
const isOverlayVisible = ref(false);

const toggleOverlay = () => {
  isOverlayVisible.value = !isOverlayVisible.value;
};
</script>


<style lang="scss">
@import "@/assets/scss/main";
@import 'leaflet/dist/leaflet.css';

</style>
