// src/analytics.ts
export const initGA = (): void => {
    // Assuming gtag.js script is already included in the index.html
    window.dataLayer = window.dataLayer || [];
    function gtag(...args: any[]) { window.dataLayer.push(args); }
    gtag("js", new Date());
    gtag("config", "G-VJE747N81W");
};

export const trackPageView = (url: string): void => {
    window.gtag && window.gtag("config", "G-VJE747N81W", { page_path: url });
};
