<template>
  <footer>
    <div id="footer-container">
      <div style="flex-direction: row; display: flex; gap: 16px;">
        <div style="display: flex; flex-direction: column; gap: 8px;">
          <p>&copy; 2023 Innovators Hub</p>
          <p>{{ translator.trans("general.footer.vat") }}
            {{ translator.trans("general.footer.number_short") }}: 0740.610.638
          </p>
            <a href="https://www.matchoffice.be/huren/locaties/providers/groep-coenen-13144" target="_blank">
              <img src="@/assets/images/MO-partner-badge.svg" alt="MO Partner Badge">
            </a>
        </div>
        <div id="footer-horizontal-line">
        </div>
        <div style="text-align:right;">
          <p>Zone Guldendelle</p>
          <p>Arthur de Coninckstraat 5</p>
          <p>Kortenberg, 3070</p>
          <p>Vlaams Brabant, BE</p>
          <a id="footer-maps-link"
             target="_blank"
             href="https://www.google.com/maps/place/Arthur+de+Coninckstraat+5,+3070+Kortenberg/@50.8853159,4.5182657,16.25z/data=!4m6!3m5!1s0x47c3de7dce00f233:0x3f8b688f143e4201!8m2!3d50.8853453!4d4.5223419!16s%2Fg%2F11ghp08k27?entry=ttu">
            {{ translator.trans("general.footer.view_on_gm") }}
          </a>
        </div>
        <div id="footer-location-icon-container">
          <img src="@/assets/images/location-pin.svg" alt="Location Icon" width="28" >
        </div>
      </div>
      <div id="footer-socials-container">
        <a href="https://www.facebook.com/innovatorshubkortenberg" target="_blank">Facebook</a>
        <span>&diamondsuit;</span>
        <a href="https://www.linkedin.com/company/innovatorshub-kortenberg/?viewAsMember=true" target="_blank">Linkedin</a>
      </div>
    </div>
  </footer>
</template>

<script lang="ts" setup>
import {getCurrentInstance} from "vue";

const instance = getCurrentInstance();
const translator = instance?.appContext.config.globalProperties.$translatorUtils;
</script>
<style lang="scss" scoped>
@import "@/assets/scss/footer";
</style>