import NestedRecord from "@/types/interfaces/NestedRecord";
import Language from "@/types/enums/language";

import generalEn from "@/lang/translations/en/general";
import generalNl from "@/lang/translations/nl/general";
import officesNl from "@/lang/translations/nl/offices";
import officesEn from "@/lang/translations/en/offices";
import facilitiesNl from "@/lang/translations/nl/facilities";
import facilitiesEn from "@/lang/translations/en/facilities";

interface LanguageMap {
  [key: string]: string;
}

class Translator {
  private readonly files = {
    [Language.EN]: {
      general: generalEn,
      offices: officesEn,
      facilities: facilitiesEn
    },
    [Language.NL]: {
      general: generalNl,
      offices: officesNl,
      facilities: facilitiesNl
    }
  } as NestedRecord;

  setLanguage(lang: string) {
    localStorage.setItem("language", lang);
  }
  getLanguage(): string {
    let lang = localStorage.getItem("language") ?? "en";
    if (!lang || lang === "undefined") {
      this.setLanguage("en");
      lang = "en";
    }
    return lang;
  }

  trans(code: string, vars: string[] = []): string {
    let lang = this.getLanguage();
    if (!lang || lang === "undefined") {
      this.setLanguage("en");
      lang = "en";
    }
    if (!this.files || !this.files[lang]) {
      console.warn(`Translation files for ${lang} not found`);
      return code;
    }
    let file = this.files[lang] as NestedRecord;

    const keys = code.split(".");

    const lastIndex = keys.length - 1;
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];

      if (!(key in file)) return code;

      if (i === lastIndex && typeof file[key] === "string") {
        let result = file[key] as string;

        for (const v in vars) {
          result = result.replaceAll(v, vars[v]);
        }
        return result;
      }

      file = file[key] as NestedRecord;
    }
    return code;
  }
  formatCurrency(value: number): string {
    if (value === undefined) return "";
    return value.toLocaleString(this.getLocale(), {
      style: "currency",
      currency: "EUR"
    });
  }
  formatNumber(value: number): string {
    if (value === undefined) return "";
    return value.toLocaleString(this.getLocale(), {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });
  }
  getLocale(): string {
    const languageCode = localStorage.getItem("language") ?? "en";
    const languageMap: LanguageMap = {
      EN: "en-GB",
      NL: "nl-BE"
    };

    return languageMap[languageCode.toUpperCase()] || "en-GB";
  }
}

export default new Translator();
