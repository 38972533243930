export default {
    header: {
        welcome_to: "Welcome to",
        sub_title: "From brainstorm to boardroom: office spaces designed for the modern innovator's every need.",
        learn_more: "Learn more",
        rent_office: "Rent an office"
    },
    about_us: {
        top_text: "Quick & easy",
        sub_text: "Our way of working",
        find_solution: {
            title: "Find your solution",
            description: "We have several office spaces available to choose from."
        },
        agreement: {
            title: "Agreement",
            description: "After choosing your workspace we'll find an agreement."
        },
        details: {
            title: "Discuss the details",
            description: "Together we discuss your goals and expectations."
        },
        enjoy: {
            title: "Enjoy your office",
            description: "Your office is ready for you!"
        },
        rent_office_strong: "Rent an office",
        challenges_title: "The Challenges Innovators Face",
        challenges_description: "Innovators are often highly skilled in their respective domains, yet they may face challenges in other crucial areas of running a business, such as finance, marketing, and legal matters. At Innovators Hub, our extensive experience gained from decades of business operations and successful company startups has inspired us to offer an experience-driven approach that can greatly enhance your organization and business.",
        solution_title: "Our Solution: Bridging Innovation and Business",
        solution_description: "Our comprehensive approach acts as a bridge between innovative ideas and practical business strategies. With our deep expertise in finance, marketing, legal, and other essential areas, we provide the necessary catalyst to propel your organization to new heights."
    },
    partners: {
        top_text: "Meet our esteemed partners",
        sub_text: "Our Partners - Innovator Insiders",
        go_watts: {
            name: "Go Watts",
            description: "Ontzorgd elektrisch laden",
            visit_website: "Visit their website"
        },
        groep_coenen: {
            name: "Groep Coenen",
            description: "Keep it simple - Keep it efficient",
            visit_website: "Visit their website"
        }
    },
    contact_us: {
        top_text: "Contact Us",
        sub_text: "Innovation begins with conversation. Start one with us today.",
        form: {
            name_label: "Name:",
            message_label: "Message:",
            send_button: "Send"
        },
        connect_with_us: "Connect with us",
        socials: {
            facebook: "FACEBOOK",
            linkedin: "LINKEDIN"
        }
    },
    footer: {
        vat: "VAT",
        number_short: "No",
        view_on_gm: "View on google maps"
    }
}

