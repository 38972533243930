<template>
  <div id="about-us">
    <section id="about-us-section">
      <div id="about-us-roadmap">
        <p class="section-top-text" id="about-us-top-text">{{ translator.trans("general.about_us.top_text") }}</p>
        <h2 class="section-sub-text">{{ translator.trans("general.about_us.sub_text") }}</h2>
        <div id="about-us-roadmap-card-container">
          <div class="about-us-roadmap-card">
            <div class="about-us-roadmap-img-container"
                 id="about-us-roadmap-one">
              <img src="@/assets/images/roadmap/magnifying-glass.svg"
                   alt="Step 1: search"
                   class="about-us-roadmap-img">
            </div>
            <h3 class="roadmap-h3">{{ translator.trans("general.about_us.find_solution.title") }}</h3>
            <p class="roadmap-p">{{ translator.trans("general.about_us.find_solution.description") }}</p>
          </div>
          <div class="about-us-roadmap-card">
            <div class="about-us-roadmap-img-container"
                 id="about-us-roadmap-two">
              <img src="@/assets/images/roadmap/agreement-handshake.svg"
                   alt="Step 2: agreement"
                   class="about-us-roadmap-img">
            </div>
            <h3 class="roadmap-h3">{{ translator.trans("general.about_us.agreement.title") }}</h3>
            <p class="roadmap-p">{{ translator.trans("general.about_us.agreement.description") }}</p>
          </div>
          <div class="about-us-roadmap-card">
            <div class="about-us-roadmap-img-container"
                 id="about-us-roadmap-three">
              <img src="@/assets/images/roadmap/discuss-the-details.svg"
                   alt="Step 3: details"
                   class="about-us-roadmap-img">
            </div>
            <h3 class="roadmap-h3">{{ translator.trans("general.about_us.details.title") }}</h3>
            <p class="roadmap-p">{{ translator.trans("general.about_us.details.description") }}</p>
          </div>
          <div class="about-us-roadmap-card">
            <div class="about-us-roadmap-img-container"
                 id="about-us-roadmap-four">
              <img src="@/assets/images/roadmap/celebration.svg"
                   alt="Step 4: enjoy"
                   class="about-us-roadmap-img">
            </div>
            <h3 class="roadmap-h3">{{ translator.trans("general.about_us.enjoy.title") }}</h3>
            <p class="roadmap-p">{{ translator.trans("general.about_us.enjoy.description") }}</p>
          </div>
        </div>
        <div id="about-us-roadmap-btn-container">
          <a class="rent-an-office-btn" id="about-us-roadmap-btn" href="/offices">
            <span><strong>{{ translator.trans("general.header.rent_office") }}</strong></span>
          </a>
        </div>
      </div>
      <div id="about-us-flex">
        <div class="about-us-div">
          <h3>{{ translator.trans("general.about_us.challenges_title") }}</h3>
          <p class="about-us-p">{{ translator.trans("general.about_us.challenges_description") }}</p>

        </div>
        <div class="about-us-div">
          <h3>{{ translator.trans("general.about_us.solution_title") }}</h3>
          <p class="about-us-p">{{ translator.trans("general.about_us.solution_description") }}</p>

        </div>
      </div>
    </section>
  </div>
</template>

<script lang="ts" setup>
import {getCurrentInstance} from "vue";

const instance = getCurrentInstance();
const translator = instance?.appContext.config.globalProperties.$translatorUtils;
</script>

<style lang="scss">
@import "@/assets/scss/about-us";
</style>