<template>
  <section id="partners">
    <div id="partners-container">
      <p class="section-top-text" id="partners-top-text">{{ translator.trans("general.partners.top_text") }}</p>
      <h2 class="section-sub-text" id="partners-sub-text">{{ translator.trans("general.partners.sub_text") }}</h2>
      <div id="partners-card-container">
        <div id="go-watts" class="partner-card">
          <div class="image-container">
            <img class="partner-logo" src="@/assets/images/partners/go-watts.png" alt="Go Watts logo">
            <div class="overlay">
              <a href="https://www.facebook.com/gowatts" target="_blank">
                <img width="30"
                     src="@/assets/images/socials/facebook-white.svg"
                     alt="Groep Coenen logo">
              </a>
              <a href="https://gowatts.be" target="_blank">
                <img width="25"
                     src="@/assets/images/socials/website.svg"
                     alt="Groep Coenen logo">
              </a>
            </div>
          </div>
          <div class="partner-info-container">
            <h3 class="partner-name">{{ translator.trans("general.partners.go_watts.name") }}</h3>
            <p class="partner-description">{{ translator.trans("general.partners.go_watts.description") }}</p>
          </div>
          <a class="visit-website" href="https://gowatts.be" target="_blank">{{ translator.trans("general.partners.go_watts.visit_website") }}</a>
        </div>
        <div id="groep-coenen" class="partner-card">
          <div class="image-container">
            <img class="partner-logo" id="coenen-logo" src="@/assets/images/partners/groep-coenen.png" alt="Groep Coenen logo">
            <div class="overlay">
              <a href="https://www.coenen.be" target="_blank">
                <img width="25"
                     src="@/assets/images/socials/website.svg"
                     alt="Groep Coenen logo">
              </a>
            </div>
          </div>
          <div class="partner-info-container">
            <h3 class="partner-name">{{ translator.trans("general.partners.groep_coenen.name") }}</h3>
            <p class="partner-description">{{ translator.trans("general.partners.groep_coenen.description") }}</p>
          </div>
          <a class="visit-website" href="https://www.coenen.be" target="_blank">{{ translator.trans("general.partners.groep_coenen.visit_website") }}</a>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts" setup>

import {getCurrentInstance} from "vue";

const instance = getCurrentInstance();
const translator = instance?.appContext.config.globalProperties.$translatorUtils;


</script>

<style lang="scss" scoped>
@import "@/assets/scss/partners";
</style>