import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import HomeView from "../views/HomeView.vue";

const routes: Array<RouteRecordRaw> = [

    {
      path: "/",
      name: "home",
      component: HomeView
    },
  {
    path: "/offices",
    name: "offices",
    component: () => import("../views/OfficesView.vue")
  },
  // Default route if no language is specified
  {
    path: "/",
    redirect: "home" // Default to English. Adjust as needed.
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to, from, next) => {
  const language = localStorage.getItem("language");
  if (!language || language === "undefined"){
    localStorage.setItem("language","en");
  }

  next();
});

export default router;
