export default {
    our_assets: "Our assets",
    for_your_comfort: "Everything for your comfort",
    general_facilities: "General Facilities",
    general_facilities_desc: "At Innovators Hub, it's not just about the space, but also about the extras that make your day more enjoyable. Benefit from toilet facilities on every floor, and even two showers on the ground floor. Whether you have a busy day or just want to relax, we have thought of everything.",
    our_offices: "Our Offices",
    location: {
        title: "Location",
        description: "The ideal office location: Kortenberg is an excellent location for your office space, strategically located between Brussels and Leuven. This favorable location not only offers easy accessibility through major thoroughfares and public transport but also situates your business in an environment that is both dynamic and calming.",
        free_parking_title: "Free Parking",
        free_parking_text: "Ample parking space for you and your clients.",
        charging_stations_title: "Charging Stations",
        charging_stations_text: "Quick and efficient charging points for your electric vehicles.",
        public_transport_title: "Public Transport",
        public_transport_text: "Train station at 1.5km, bus stop at 400m."
    },
    facilities: {
        facilities: "Facilities",
        internet: "High-speed Internet: Reliable and fast internet in all office spaces",
        meeting: "Meeting Rooms: Equipped meeting rooms available for tenants.",
        kitchen: "Coffee and Kitchen Facilities: Coffee and a fully-equipped kitchen.",

        flexibility: "Flexibility",
        short_term: "Short-Term Rentals: Option to rent by the day, week, or month.",
        scalability: "Scalability: Easy to expand as your business grows.",

        services: "Extra Services",
        advice: "Accounting and Legal Advice: External experts available for consultation.",
        printing: "Printing and Copying Services: Printers and copiers available for general use.",

        ambience_design: "Ambience and Design",
        durability: "Sustainability: Environmentally-friendly features like solar panels, recycling, etc."
    },
    office_types: {
        compact_but_complete: "Compact but Complete",
        office_for_2: "Office for 2 to 3 people",
        area_20_25: "20 to 21m²",
        general_text_small: "Our two-person offices are perfect for freelancers, small startups, or as a satellite office. These offices come fully equipped with desks and office chairs, but can also be delivered empty if you wish.",
        small_office_alt: "Small office with 2 people working",

        room_to_grow: "Room to Grow",
        office_up_to_4: "Office up to 4 to 6 people",
        area_25_30: "23 to 27m²",
        general_text_medium: "With room for a team of four, these offices are ideal for smaller companies or departments wanting to collaborate in a professional setting. Like our other offices, these spaces come fully equipped but can be delivered empty upon request.",
        medium_office_alt: "Medium-sized office with up to 4 people working",

        max_space_max_productivity: "Maximum Space, Maximum Productivity",
        office_up_to_10: "Office for 10 to 16 people",
        area_48: "51 to 120m²",
        general_text_large: "Our largest offices can accommodate teams of up to 10 people and come equipped with everything you need to be productive. As with our smaller options, furnishings can be removed to meet your needs.",
        large_office_alt: "Large office with up to 10 people working"
    }


};
