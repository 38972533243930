<template>
  <header>
    <div id="header-container">
      <div id="header-left">
        <h1 id="header-title">
          {{ translator.trans("general.header.welcome_to") }} Innovators Hub
        </h1>
        <h3 id="header-sub-title">
          {{ translator.trans("general.header.sub_title") }}
        </h3>
        <div id="header-button-container">
          <a class="header-link " id="header-learn-more" href="#about-us">{{ translator.trans("general.header.learn_more") }}</a>
          <a class="header-link rent-an-office-btn" href="/offices">
            <span>{{ translator.trans("general.header.rent_office") }}</span>
          </a>
        </div>
      </div>
      <div id="header-right">
        <div id="header-img-container">
          <img id="header-img" src="@/assets/images/header/header-image-2.png" alt="Header image">
        </div>
      </div>
    </div>
  </header>
  <main>
    <AboutUs></AboutUs>
    <PartnersView></PartnersView>
    <ContactUs></ContactUs>
  </main>
</template>

<script lang="ts" setup>

import AboutUs from "@/components/AboutUs.vue";
import PartnersView from "@/components/PartnersView.vue";
import ContactUs from "@/components/ContactUs.vue";
import {getCurrentInstance} from "vue";

const instance = getCurrentInstance();
const translator = instance?.appContext.config.globalProperties.$translatorUtils;

</script>
<style lang="scss">
@import "@/assets/scss/main";
</style>

