import router from "@/router";
import {RouteParamsRaw, useRoute} from "vue-router";

class Router {
  async routeTo(name: string, params: RouteParamsRaw = {}) {
    await router.push({ name: name, query: params });
  }

  getBreadCrumbs() {
    return router.currentRoute.value.fullPath.split("/").map((el) => ({
      name: el === "" ? "home" : el
    }));
  }

  refreshCurrentPage() {
    router.go(0);
  }

  getQueryParams() {
    const route = useRoute();
    return route.query;
  }

}

export default new Router();
