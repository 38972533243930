import { App } from "vue";
import Router from "@/types/services/Router";

const routerPlugin = {
    install(app: App) {
        app.config.globalProperties.$routerUtils = Router;
    }
};

export default routerPlugin;
